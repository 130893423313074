export const pbCup = {
  id: "milkshakePbCup",
  backgroundColor: "#C65100",
  textColor: "#FFFFFF",
  name: "Peanut Butter Cup made with Reese® Peanut Butter Cups®",
  tagline: "It's peanut butter milkshake time",
  description:
    "If you can't make everyone happy, then you obviously aren't a Reese's Peanut Butter Cup Milkshake. But what you can do is enjoy rich, chocolate peanut buttery perfection made with real Reese's Peanut Butter Cups, and that's basically the same thing. Drink happy thoughts.",
  ingredients:
    "Modified milk ingredients, Water, Sugars (sugar, glucose), Cream, Reese® Peanut Butter Cups® (sugar, cocoa butter, chocolate liquor, milk ingredients, soy lecithin, polyglycerol polyricinoleate, peanuts, dextrose, salt, TBHQ, citric acid), Milk chocolate chips (sugar, chocolate liquor, milk ingredients, cocoa butter, soy lecithin, natural flavor), Peanut butter (peanuts, peanut oil, sugar, salt, cornstarch), Carob bean gum, Guar gum, Carrageenan.",
  disclaimer:
    "Contains: Milk, Peanuts, Soy. May contain: Tree nuts, Wheat, Egg from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "450",
    values: [
      {
        type: "primary",
        label: "Lipides",
        value: "20g",
        percent: "27%",
      },
      {
        type: "secondary",
        label: "Saturés<br>+ Trans",
        value: "11g<br>0.3g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Glucides",
        value: "46g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibres",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sucres",
        value: "54g",
        percent: "54%",
      },
      {
        type: "primary",
        label: "Protéines",
        value: "7g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholestérol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "300mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "600mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "200mg",
        percent: "15%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.5mg",
        percent: "3%",
      },
    ],
  },
}

export const cookiesCream = {
  id: "milkshakeCookiesCream",
  backgroundColor: "#3B1E00",
  textColor: "#FFFFFF",
  name: "Cookies & Cream",
  tagline: "The Ultimate Dream Team",
  description: false,
  ingredients:
    "Modified milk ingredients, Water, Sugars (sugar, glucose), Cream, Crème filled chocolate cookies (enriched flour, sugar, palm and palm kernel oils, cocoa, corn flour, salt, sodium bicarbonate, dextrose, soy lecithin, natural flavours), Natural and artificial vanilla flavour, Carob bean gum, Guar gum, Carrageenan.",
  disclaimer:
    "Contains: Milk, Wheat, Soy. May contain: Peanuts, Tree nuts, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "420",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "15g",
        percent: "20%",
      },
      {
        type: "secondary",
        label: "Saturated<br>+ Trans",
        value: "9g<br>0.3g",
        percent: "45%",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "47g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "51g",
        percent: "51%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "55mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "250mg",
        percent: "11%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "1mg",
        percent: "6%",
      },
    ],
  },
}

export const chocolateChill = {
  id: "milkshakeChocolateChill",
  backgroundColor: "#78452B",
  textColor: "#FFFFFF",
  name: "Chocolate",
  tagline: "Chocoholics Unite",
  description:
    "Chocolate makes the world go round, everybody knows that. That's why we took it to another level and mixed real cocoa with Milk and Cream to create the greatest chocolate shake on the planet. Yes, we said it. Get ready to satisfy your soul.",
  ingredients:
    "Modified milk ingredients, Sugars (sugar, glucose), Water, Cream, Cocoa, Carob bean gum, Guar gum, Carrageenan.",
  disclaimer:
    "Contains: Milk. May contain: Peanuts, Tree nuts, Soy, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "450",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "16g",
        percent: "21%",
      },
      {
        type: "secondary",
        label: "Saturated<br>+ Trans",
        value: "10g<br>0.5g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "74g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "2g",
        percent: "7%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "61g",
        percent: "61%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "280mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "40mg",
        percent: "1%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "3mg",
        percent: "17%",
      },
    ],
  },
}

export const vanillaBliss = {
  id: "milkshakeVanillaBliss",
  backgroundColor: "#F0D08B",
  textColor: "#004E7D",
  name: "Vanilla",
  tagline: "The Classiest Shake of em' All",
  description:
    "When it comes to classics, we don't mess with them. That's why we kept it simple with dairy fresh milk and rich cream, resulting in a simply satisfying creation, and you can't go wrong with a classic. So put your pinkies up and start sipping.",
  ingredients:
    "Modified milk ingredients, Water, Sugars (sugar, glucose), Cream, Natural and artificial vanilla flavour, Carob bean gum, Guar gum, Carrageenan.",
  disclaimer:
    "Contains: Milk. May contain: Peanuts, Tree nuts, Soy, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237mL/355 mL as prepared)",
    calories: "410",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "15g",
        percent: "20%",
      },
      {
        type: "secondary",
        label: "Saturated<br>+ Trans",
        value: "9g<br>0.4g",
        percent: "45%",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "43g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "53g",
        percent: "53%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "65mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "290mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "200mg",
        percent: "15%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.1mg",
        percent: "1%",
      },
    ],
  },
}

export const coolMintChip = {
  id: "milkshakeCoolMintChip",
  backgroundColor: "#75E1CA",
  textColor: "#004E7D",
  name: "Mint Chip",
  tagline: "Mint Chip",
  description:
    "It's true, you and this shake were mint for each other. Delicious chocolate chips and a crisp minty flavor makes this the freshest and coolest shake on the blender.",
  ingredients:
    "Modified milk ingredients, Water, Sugars (sugar, glucose), Cream, Chocolate chips (sugar, bitter chocolate, cocoa butter, natural vanilla extract), Carob bean gum, Guar gum, Carrageenan, Natural flavour, Turmeric extract, Brilliant blue.",
  disclaimer:
    "Contains: Milk. May contain: Peanuts, Tree nuts, Soy, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "440",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "17g",
        percent: "23%",
      },
      {
        type: "secondary",
        label: "Saturated<br>+ Trans",
        value: "11g<br>0.3g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "48g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "55g",
        percent: "55%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "270mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.75mg",
        percent: "4%",
      },
    ],
  },
}

export const cottonCandy = {
  id: "milkshakeCottonCandy",
  backgroundColor: "#51BDF5",
  textColor: "#004E7D",
  name: "Cotton Candy",
  tagline: "Cotton Candy",
  description: false,
  ingredients:
    "Modified milk ingredients, Water, Sugars (sugar, glucose), Cream, Modified cornstarch, Carob bean gum, Guar gum, Citric acid, Natural and artificial flavours, Titanium dioxide, Brilliant blue, Potassium sorbate, Carrageenan.",
  disclaimer:
    "Contains: Milk. May contain: Peanuts, Tree nuts, Soy, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "430",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "14g",
        percent: "19%",
      },
      {
        type: "secondary",
        label: "Saturated<br>+ Trans",
        value: "9g<br>0.4g",
        percent: "45%",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "50g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "57g",
        percent: "57%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "5g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "60mg",
        percent: "",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "280mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "650mg",
        percent: "14%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "175mg",
        percent: "13%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.1mg",
        percent: "1%",
      },
    ],
  },
}
